<template>
  <b-card v-if="azienda">
    <b-card-title>
      <b-row>
        <b-col :cols="allowAddUser ? 8 : 12">
          {{ azienda.ragioneSociale}}
        </b-col>
        <b-col v-if="allowAddUser" cols="4">
          <b-button
            @click="$router.push('createUser')"
            :variant="`${isDark ? 'warning' : 'primary'}`"
            block
          >
            Aggiungi utente
          </b-button>
        </b-col>
        <b-col v-else cols="4">
          <div style="border-left:2px solid red;" class="p-1 mb-1">
            <p class="card-text text-primary h5">
              Il tuo abbonamento ha raggiunto il numero masssimo di utenti: <b>{{this.azienda.subscription.maxUsers}}</b>
            </p>
            <p class="card-text text-secondary">
              Puoi aggiungere utenti estendendo l'<b>abbonamento</b>
            </p>
          </div>
        </b-col>
      </b-row>
    </b-card-title>
    <b-card-sub-title>
      Elenco utenti
    </b-card-sub-title>
    <b-table
       small
       :fields="fields"
       :items="items"
       responsive="sm"
     />
  </b-card>
</template>

<script>
import { BCard, BCardTitle, BCardSubTitle, BTable, BBadge, BRow, BCol, BButton } from 'bootstrap-vue'
import useAppConfig from '@core/app-config/useAppConfig'
// import { computed } from '@vue/composition-api'
import { computed } from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      azienda: null,
      dateOptions: {  timeStyle:"short", dateStyle: 'medium' },
      fields: [
        // 'index',
        {
          key: 'username',
          label: 'Username'
        },
        {
          key: 'fullname',
          label: 'Nome completo'
        },
        {
          key: 'email',
          label: 'Email'
        },
        {
          key: 'createdAt',
          label: 'Creato',
          formatter: value => new Date(value).toLocaleString('it-IT', this.dateOptions)
        },
        {
          key: 'activatedAt',
          label: 'Attivato',
          formatter: value => value ? new Date(value).toLocaleString('it-IT', this.dateOptions) : ''
        },
      ],
      items: [],
    }
  },
  setup() {
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')
    return { isDark }
  },
  components: {
    BCard,
    BCardTitle,
    BCardSubTitle,
    BTable,
    BBadge,
    BRow,
    BCol,
    BButton,
  },
  mounted() {
    this.getAzienda()
    this.getUsers()
  },
  computed: {
    allowAddUser() {
      return this.azienda.subscription.maxUsers > this.items.length
    },
  },
  methods: {
    getAzienda() {
      this.$http.get('/az/getDetails').then(res => {
        // console.log('AZIENDA ',res)
        this.azienda = res.data
      })
    },
    getUsers() {
      this.$http.get('/az/getAllUsers').then(res => {
        // console.log('Users ',res)
        this.items = res.data.map( u => {
          u['name'] = u.userDetail.name
          u['surname'] = u.userDetail.surname
          u['fullname'] = u.name ? u.name : ''+' '+u.surname ? u.surname : ''
          return u
        })
      })
    },
  }
}
</script>

<style>

</style>
